const envVar = {
    siteUrl: process.env.GATSBY_SITE_URL || 'https://localhost:8000',
    backgroundColor: process.env.GATSBY_BACKGROUND_COLOR || 'black',
    textColor: process.env.GATSBY_TEXT_COLOR || 'white',
    menuTextColor: process.env.GATSBY_MENU_TEXT_COLOR || 'black',
    fbUrl: 'https://www.facebook.com/DOCUMENTARYPLUSAPP',
    twitterUrl: 'https://twitter.com/documentaryplus',
    ytUrl: 'https://www.youtube.com/channel/UCtxWoJSQhvVizDLNI2yb7xA',
    igUrl: 'https://www.instagram.com/documentaryplus/',
    appleTvUrl: 'https://apps.apple.com/us/app/documentary/id1546625549',
    rokuUrl: 'https://channelstore.roku.com/details/ac0714c157f8f75df99bd6ac1d937cb3/documentary',
    androidUrl: 'https://play.google.com/store/apps/details?id=com.docplus.android.mobile&hl=en_US&gl=US',
    iosUrl: 'https://apps.apple.com/us/app/documentary/id1546625549',
    fireTvUrl: 'https://www.amazon.com/XTR-LLC-DOCUMENTARY/dp/B08TQDZZN9/ref=sr_1_3?dchild=1&keywords=documentary+plus&qid=1622770288&s=amazon-devices&sr=1-3',
    baseUrl: process.env.GATSBY_BASE_URL,
    configUrl: process.env.GATSBY_JWAPP_CONFIG_URL,
    searchUrl: process.env.GATSBY_SEARCH_API_URL,
    relatedFilmsId: process.env.GATSBY_JWPLAYER_RELATED_FILMS_ID || 'oNziQozS',
    jwPlayerLicenseKey: process.env.GATSBY_JWPLAYER_LICENSE_KEY,
    jwPlayerScriptUrl: process.env.GATSBY_JWPLAYER_SCRIPT_URL,
    adscheduleid: process.env.GATSBY_AD_SCHEDULE_ID,
    fallabckAdUrl: process.env.FALLBACK_AD_URL
};

export default envVar;