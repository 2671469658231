// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-category-[title]-feed-id-js": () => import("./../../../src/pages/category/[title]/[feedId].js" /* webpackChunkName: "component---src-pages-category-[title]-feed-id-js" */),
  "component---src-pages-details-[title]-media-id-js": () => import("./../../../src/pages/details/[title]/[mediaId].js" /* webpackChunkName: "component---src-pages-details-[title]-media-id-js" */),
  "component---src-pages-films-js": () => import("./../../../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-hls-stream-js": () => import("./../../../src/pages/hls-stream.js" /* webpackChunkName: "component---src-pages-hls-stream-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-ninetyfivesenses-index-js": () => import("./../../../src/pages/ninetyfivesenses/index.js" /* webpackChunkName: "component---src-pages-ninetyfivesenses-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quietminds-index-js": () => import("./../../../src/pages/quietminds/index.js" /* webpackChunkName: "component---src-pages-quietminds-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-series-[title]-[season]-[episode]-number-episode-media-id-js": () => import("./../../../src/pages/series/[title]/[season]/[episodeNumber]/[episode]/[mediaId].js" /* webpackChunkName: "component---src-pages-series-[title]-[season]-[episode]-number-episode-media-id-js" */),
  "component---src-pages-series-[title]-media-id-js": () => import("./../../../src/pages/series/[title]/[mediaId].js" /* webpackChunkName: "component---src-pages-series-[title]-media-id-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thesentence-index-js": () => import("./../../../src/pages/thesentence/index.js" /* webpackChunkName: "component---src-pages-thesentence-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-details-js": () => import("./../../../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

