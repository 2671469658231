import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import env from 'config';

let theme = createTheme({
  appBar: {
    backgroundColor: env.backgroundColor,
  },
  body: {
    backgroundColor: env.backgroundColor,
    color: env.textColor,
    menuTextColor: env.menuTextColor,
  },
  palette: {
    primary: {
      main: "#0084E4",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f5cb39",
      contrastText: "#ffffff",
    },
    background: {
      dark: "#000000",
      light: "#F2F1F6",
      alt: "#A7CFE8",
      paper: "#F2F2F5",
      white: "#FFFFFF",
    },
  },
  typography: {
    color: env.textColor,
    fontFamily: ["Alte-Haas-Grotesk", 'HelveticaBold'].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.2rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.6rem",
      fontWeight: 400,
    },

    h5: {
      fontSize: "1.4rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },
    caption: {
      lineHeight: "0.5rem",
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
      },
    },
    MuiTypographyRoot: {
      color: "white",
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
